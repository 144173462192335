<template>
    <div>
        <div v-if="authUserPermission['workflows-update']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('setting_workflows') }}</h3>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="danger" @click="updateWorkflow">{{ tt('update') }}</base-button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('code')" :placeholder="tt('code')" v-model="workflows.workflows_code" rules="required"></base-input>

                            <label class="form-control-label">{{ tt('workflows') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('workflows')" :placeholder="tt('workflows')" v-model="workflows.workflows" rules="required"></base-input>

                            <label class="form-control-label">{{ tt('status') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('status')" rules="required">
                                <el-select class="select-danger" v-model="workflows.active" placeholder="Choose Status">
                                    <el-option class="select-danger" value="Active" label="Active" key="Active"></el-option>
                                    <el-option class="select-danger" value="Non Active" label="Non Active" key="Non Active"></el-option>
                                </el-select>
                            </base-input>

                            <label class="form-control-label">{{ tt('source_type') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('source_type')" rules="required">
                                <el-select class="select-danger" v-model="workflows.workflows_source_type" placeholder="Choose Source Type">
                                    <el-option class="select-danger" value="table-column" label="Table Column" key="Table Column"></el-option>
                                    <el-option class="select-danger" value="query" label="Query" key="Query"></el-option>
                                </el-select>
                            </base-input>

                            <div v-if="workflows.workflows_source_type == 'table-column'">
                                <label class="form-control-label">{{ tt('table_column') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('table_column')" rules="required">
                                    <el-select class="select-danger" v-model="workflows.source" :placeholder="tt('choose_source')">
                                        <el-option class="select-danger" :value="tc['table_column_code']" :label="tc['table_column_code'] + ' - ' + tc['table']" :key="tc['table_column_code'] + ' - ' + tc['table']" v-for="tc in tableColumn">{{ tc['table_column_code'] }} - {{ tc['table'] }}</el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div v-else-if="workflows.workflows_source_type == 'query'">
                                <label class="form-control-label">{{ tt('query') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('query')" rules="required">
                                    <textarea class="form-control" placeholder="select * from company where id = {$key}" rows="3" v-model="workflows.source"></textarea>
                                </base-input>
                            </div>

                            <base-button size="sm" class="mb-4" type="default" @click="previewParameter">{{ tt('preview_parameter') }}</base-button>

                            <base-input :label="tt('preview')">
                                <textarea class="form-control" rows="3" :placeholder="tt('preview')" readonly v-model="prevParam"></textarea>
                            </base-input>
                        </div>
                    </div>

                    <div class="card-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('condition') }}</h3>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="search.condition" :placeholder="tt('search')" v-on:keyup="filter"/>                            
                                </div>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                        <div>
                            <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="conditions" v-if="!onLoad">
                                <el-table-column :label="tt('condition')" :prop="tt('condition')" sortable>
                                    <template v-slot="{row}">
                                        {{row.condition}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="action" width="100">
                                    <template v-slot="{row}">
                                        <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                            <span class="btn btn-sm btn-icon-only text-light">
                                                <i class="fas fa-ellipsis-v mt-2"></i>
                                            </span>
                                            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                <el-dropdown-item :command="{action:'diagram', data:row.id}">{{ tt('diagram') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'editCondition', data:row.id}">{{ tt('edit') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'duplicate', id:row.id, condition:row.condition, workflows_code:row.workflows_code}">{{ tt('duplicate') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'remove', data:row.id}">{{ tt('delete') }}</el-dropdown-item> 
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <skeleton-loading v-else/>
                        </div>
                        <div class="card-footer pb-0 ">                               
                            <span class="float-right">
                                {{ conditions.length }} Conditions
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MODAL CONDITIONS -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ form.title }}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('condition') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('condition')" :placeholder="tt('condition')" v-model="condition.condition" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MODAL DUPLICATE CONDITION -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formDuplicate">
                    <template slot="header">
                        <h5 class="modal-title">{{ form.title }}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('condition') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('condition')" :placeholder="tt('condition')" v-model="duplicateCondition.condition" rules="required"></base-input>
                        <label class="form-control-label">{{ tt('workflow_code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('table_column')" rules="required">
                            <el-select class="select-danger" v-model="duplicateCondition.workflows_code" :placeholder="tt('choose_source')">
                                <el-option class="select-danger" :value="wf['workflows_code']" :label="wf['workflows_code']" :key="wf['workflows_code']" v-for="wf in dataWorkflows">{{ wf['workflows_code'] }}</el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formDuplicate = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveDuplicate()" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span>{{ tt('duplicate') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import workflows from '@/services/setting/workflows.service';
    import condition from '@/services/setting/condition.service';
    import tableColumn from '@/services/setting/tableColumn.service';

    export default {        
        data() {
            return {             
                onLoad: true,      
                loadTimeout: null, 
                formDuplicate: false,
                btnSave: {
                    onLoading: false
                },         
                form: {
                    add: true,
                    show: false
                },    
                no: 0,  
                tableColumn: {},
                workflows: {},
                conditions: [],
                duplicateCondition: {
                    id: '',
                    condition: '',
                    workflows_code: '',
                },
                search: {
                    condition: '',
                },
                dataWorkflows: [],
                condition: {},
                authorization: '',
                prevParam: [],
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get();
            this.getTableColumn();
            this.getWorkflows();
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, workflows.show(context.$route.params.id, {search: this.search.condition})).onSuccess(function(response) {    
                    context.workflows = response.data.data.workflows;
                    context.conditions = response.data.data.condition;             
                }).onFinish(function() {
                    context.onLoad = false;
                }).call();
            },
            updateWorkflow() {
                let context = this;               
                Api(context, workflows.update(context.workflows.id, context.workflows)).onSuccess(function(response) {
                    context.condition = response.data.data;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });                         
                }).call()   
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'diagram':
                            window.open('/workflows/index.html?id='+command.data);
                        break;
                    case 'editCondition':
                            this.editCondition(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    case 'duplicate':
                            this.duplicate(command.id, command.condition, command.workflows_code);
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_condition');
                this.form.show = true;
                this.condition = {};
            },            
            editCondition(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, condition.show(id)).onSuccess(function(response) {
                    context.condition = response.data.data;
                    console.log(context.condition)
                    context.form.add = false;
                    context.form.title = context.tt('edit_condition');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {           
                let context = this;           
                let api = null;
                context.btnSave.onLoading = true;
                context.onLoad = true;

                context.condition = {
                    workflows_code: context.workflows.workflows_code,
                    condition: context.condition.condition,
                    id: context.condition.id,
                    start_step: context.condition.start_step,
                    created_at: context.condition.created_at,
                    updated_at: context.condition.updated_at,
                }
                if (context.form.add) {
                    api = Api(context, condition.create(context.condition));
                } else {{
                    api = Api(context, condition.update(context.condition.id, context.condition));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        context.onLoad = true;
                        Api(context, condition.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            duplicate(id, condition, workflows_code) {
                this.errorMessage = null;
                let context = this;   
                context.formDuplicate = true;
                context.form.title = context.tt('duplicate_condition');            
                context.duplicateCondition.id = id;
                context.duplicateCondition.condition = condition;
                context.duplicateCondition.workflows_code = workflows_code;
            },
            saveDuplicate() {
                let context = this;               
                Api(context, condition.duplicateCondition(context.duplicateCondition.id,  context.duplicateCondition)).onSuccess(function(response) {
                    context.get();
                    context.formDuplicate = false;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });                         
                }).call() 
            },
            previewParameter() {
                if (this.workflows.workflows_source_type == 'table-column') {
                    let context = this;
                    Api(context, workflows.prevParamColumn(context.workflows.source)).onSuccess(function(response) {
                        let data           = response.data.data
                        let propertyValues = data.map(({ column_name }) => column_name);
                        context.prevParam  = propertyValues;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.prevParam = [];
                        }
                    })
                    .call()
                }else{
                    this.prevParam  = this.workflows.source
                }
            },
            getTableColumn() {
                let context = this;
                Api(context, tableColumn.get(null, {per_page: 'none'})).onSuccess(function(response) {
                    context.tableColumn = response.data.data.data.data;                          
                }).call();
            },
            getWorkflows() { 
                let context = this;               
                Api(context, workflows.get(null, { per_page: "none" })).onSuccess(function(response) {    
                    context.dataWorkflows = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.dataWorkflows = [];
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
